<template>
  <div class="page">

    <div class="data-table-container">

      <div class="tools-bar">
        <el-button-group class="i">
          <el-button @click="() => onOpenDetail({})" type="primary">新建</el-button>
          <el-button @click="load">刷新</el-button>
        </el-button-group>
        <div class="grow"></div>
        <!-- todo 搜索优惠券-->
      </div>


      <el-table stripe :data="tableData_show">
        <el-table-column prop="code" label="折扣码" width="150">
          <template slot-scope="scope">
            <a title="点击复制">
              {{scope.row.code}}</a>
            <!--<a :href="$settings.host + '/coupon/download_code_img?code=' + scope.row.code"-->
            <!--target="_blank">{{ scope.row.code }}</a>-->
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"/>
        <!--<el-table-column prop="status" label="状态" width="100"-->
        <!--:filter-multiple="false"-->
        <!--:filters="$table.filterValue(['正常', '已过期', '已废除'])"-->
        <!--:filter-method="$table.filter"/>-->
        <!--<el-table-column prop="type" label="类型" width="100"-->
        <!--:filter-multiple="false"-->
        <!--:filters="$table.filterValue(['满减', '折扣', '已废除'])"-->
        <!--:filter-method="$table.filter"/>-->
        <el-table-column prop="num" label="剩余数量" width="130" sortable/>
        <el-table-column label="优惠额" width="150" sortable>
          <template slot-scope="scope">
            <span v-if="scope.row.value_cond">满 {{ scope.row.value_cond }} 元</span>
            <span v-if="scope.row.type === '折扣'"> {{ scope.row.value_discount }} 折</span>
            <span v-if="scope.row.type === '满减'">减 {{ scope.row.value_discount }}</span>
            <span v-if="scope.row.type === '分享'">立减 {{ scope.row.value_discount }} 元</span>
          </template>
        </el-table-column>

        <el-table-column label="创建时间" width="160" prop="datetime_created"
                         :formatter="(row) => row.datetime_created.slice(0,10)"/>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button-group>
              <!-- <a class="el-button el-button--mini" target="_blank"
                 :href="`${$settings.host}/coupon/get_code_img?code=${scope.row.code}`">二维码</a> -->

              <!--              <a class="el-button el-button&#45;&#45;default el-button&#45;&#45;mini" target="_blank"-->
              <!--                 :href="$settings.host + '/get_wx_qr?url=/pages/my/coupon?code='+ scope.row.code">二维码</a>-->
              <el-button size="mini" @click="() => onOpenDetail(scope.row)">详情</el-button>
              <el-button size="mini" @click="() => handleMiniCode(scope.row)">小程序码</el-button> 
              <!--<el-button size="mini" type="danger"-->
              <!--@click="onDelete(scope.row)">删除-->
              <!--</el-button>-->

            </el-button-group>

          </template>
        </el-table-column>
      </el-table>

      <el-pagination class="pagination"
                     layout="total, prev, pager, next"
                     @current-change="load"
                     :current-page.sync="page"
                     :page-size="30"
                     :total="total"/>

    </div>


    <el-dialog :title="dialogForm.id? '兑换码详情': '新建兑换码'"
               width="800px" v-loading="dialogLoading"
               :visible.sync="dialogVisible">

      <el-form class="form" label-width="130px"
               ref="form" :rules="rules" :model="dialogForm">
        <el-form-item label="申请员工">
          {{ '啦啦之星' }}
        </el-form-item>
        <el-form-item label="券编号">
          {{ dialogForm.code }}
          <div class="tip">创建之后自动生成</div>
        </el-form-item>
        <el-form-item label="标题" prop="title" required>
          <el-input v-model="dialogForm.title" placeholder="请输入优惠券标题"/>
        </el-form-item>
        <el-form-item label="类型" required>
          <el-radio-group v-model="dialogForm.type">
            <el-radio label="折扣"></el-radio>
            <el-radio label="满减"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="折扣" v-if="dialogForm.type === '折扣'">
          <el-input-number v-model="dialogForm.value_discount" :min="1" :max="9"/>
          <div class="tip"> 折扣数额，输入 9 即 9 折</div>
        </el-form-item>
        <el-form-item label="减免金额" v-if="dialogForm.type === '满减'">
          <el-input-number v-model="dialogForm.value_discount" :min="1" :step="100"/>
          <div class="tip"> 减免金额额，输入 1000 即 立减 1000</div>
        </el-form-item>
        <el-form-item label="满足金额">
          <el-input-number v-model="dialogForm.value_cond"/>
          <div class="tip"> 【可选】满足金额才能折扣</div>
        </el-form-item>
        <el-form-item label="数量">
          <el-input-number v-model="dialogForm.num" :min="1" :step="1000"/>
          <div class="tip"> 限制领取数量，如不填，则表示不限量</div>
        </el-form-item>
        <el-form-item label="打卡奖励">
          <el-input-number v-model="dialogForm.daka_weeks"  />
          <div class="tip"> 联系打卡到第N周后自动领取</div>
        </el-form-item>
        <el-form-item label="提示">
          <el-input v-model="dialogForm.tip" placeholder="【可选】提示给顾客"/>
        </el-form-item>
        <el-form-item label="领取范围">
          <el-switch style="margin-right: 10px"
                     v-model="dialogForm.is_vip_only" active-text="仅限会员" inactive-text="所有用户"/>
        </el-form-item>

        <el-form-item label="可用时间">
          <el-date-picker style="width: 400px"
                          v-model="dialogForm.date_available_range"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="截止日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="可用商品【可选】">
          <el-transfer class="ad-select"
                       filterable
                       :titles="['可用项', '已包含']"
                       :filter-method="(query, item) => item.label.toLowerCase().indexOf(query) > -1"
                       filter-placeholder="请输入关键词"
                       v-model="dialogForm.objs_available_range"
                       :data="available_objs"/>
          <!--          </el-transfer>-->
          <!--          <div class="tip">可选</div>-->
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>

      </el-form>

    </el-dialog>


    <el-dialog title="小程序码"
               width="600px"  
               :visible.sync="miniCodeVisible">
               <div style="text-align:center">
                <img style="margin:10px auto" :src="MiniCode" alt="" class="MiniCode"/>
               </div>
    </el-dialog>

  </div>
</template>

<script>


  export default {
    data() {
      return {
        loading: false,
        dialogVisible: false,
        dialogLoading: false,
        miniCodeVisible: false,
        MiniCode:'',
        
        dialogForm: {
          user: {},
        },
        rules: {},
        inputSearch: '',
        typeSelect: '',
        typeOptions: ['类型1', '类型2'],
        tableData: [],
        available_objs: [],
        page: 1,
        total: 1
      }
    },
    mounted() {
      this.load()
    },
    computed: {
      tableData_show() {
        if (this.inputSearch) {
          return this.tableData.filter(row => row.title.includes(this.inputSearch))
        }
        return this.tableData
      }
    },
    methods: {
      async load() {
        this.loading = true
        this.$api.request('/coupon/list_all_with_page', {page: this.page}).then(data => {
          this.tableData = data.contains
          this.page = data.page
          this.total = data.total
          this.loading = false
          window.scrollTo(0, 0)
        })

        this.available_objs = await this.$api.request('/list_available_objects', {model: 'goods'})
      },
      onOpenDetail(row) {
        this.dialogVisible = true
        this.dialogForm = row
      },
      onCloseDialog() {
        this.dialogVisible = false
        this.dialogLoading = false
        this.dialogForm = {}
      },
      onSubmit() {
        const url = this.dialogForm.id ? '/coupon/update' : '/coupon/create'
        this.$api.request(url, this.dialogForm).then(data => {
          this.onCloseDialog()
          this.$message.success('提交成功！')
          this.load()
        })
      },

    handleMiniCode(record) {
      let self = this;
      self.MiniCode = window.host2+'/api/index/createCouponCodeImage?code='+record.code;
      self.miniCodeVisible = true;
    },
      onDelete({id}) {
        if (confirm('您确定要删除吗？')) {
          this.$api.request('/redeem/remove', {redeem_id: id}).then(data => {
            this.dialogForm = {}
            this.$message.success('删除成功！')
            this.load()
          })
        }
      },
    }
  }
</script>
